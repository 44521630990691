<template>
  <WsMain class="pb-80">
    <WsCreate
      ref="WsCreate"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      v-model="state"
      :emitSubmit="true"
      @input="$_onInput($event)"
      @submit="$_onSubmit()"
      :emitInput="true"
    >
    </WsCreate>
    <WsMain v-if="state&&state.fields">
      <WsCard>
        <WsText size="5">{{$t('section_content')}}</WsText>
        <WsState
          class="mt-20"
          type="form"
          :fields="state.fields"
          v-model="state.content"
        ></WsState>
      </WsCard>
    </WsMain>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_section";
export default {
  metaInfo() {
    return {
      title: `${this.$t("new")} ${this.$t("page_section")}`,
    };
  },

  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "component_name", "fields"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "項目設定",
              titleInLocale: true,
              fields: ["tags", "remark"],
            },
          ],
        },
      ],
    },
    tulpaSectionTemplate: null,
    state: {
      content: {},
    },
  }),

  methods: {
    $_onInput($event) {
      this.state = {
        ...this.state,
        ...$event,
      };
    },
    async $_onSubmit() {
      const postData = { ...this.state };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(this.state.content)))
      );
      try {
        this.$refs.WsCreate.loadingStart();
        await this.$axios.post("tulpa_section", postData);
        this.$router.push("/tulpa_section");
      } catch (error) {
        console.error(error);
        alert("儲存錯誤");
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },
};
</script>

<style>
</style>